body, html, #root {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

.pre-line {
  white-space: pre-line;
}

#testimonials, #applications, #contacts, #features {
  scroll-margin-top: 60px;
}

#gallery {
  scroll-margin-top: 120px;
}

#products {
  scroll-margin-top: 77px;
}

.no-wrap {
  white-space: nowrap;
}